<template>
  <a-modal
    :width="580"
    :visible="visible"
    :confirm-loading="loading"
    :title="'物流信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="物流公司:" name="expressCompany">
            <a-select
              allow-clear
              placeholder="请选择物流公司"
              v-model:value="form.expressCompany"
            >
              <a-select-option
                v-for="item in logDict"
                :key="item.dictDataCode"
                :value="item.dictDataName"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="物流订单号:" name="expressNo">
            <a-input
              v-model:value="form.expressNo"
              allow-clear
              placeholder="请输入物流订单号"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as dictDataApi from '@/api/sys/dictData'
import * as pointsOrderApi from '@/api/jyyw/pointsOrder'
export default {
  name: 'Logistics-Edit',
  emits: ['done', 'update:visible', 'update:data'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      logDict: [],
      // 表单验证规则
      rules: {
        expressCompany: [
          {
            required: true,
            message: '请选择物流公司',
            type: 'string',
            trigger: 'blur'
          }
        ],
        expressNo: [
          {
            required: true,
            message: '请输入物流订单号',
            type: 'string',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.queryDataStates()
  },
  mounted() {},
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
      } else {
        this.form = {}
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    save() {
      const that = this
      const data = {
        expressCompany: that.form.expressCompany,
        expressNo: that.form.expressNo,
        orderId: that.form.orderId
      }
      that.$refs.form.validate().then(() => {
        const hide = that.$message.loading('提交中..', 0)
        that.loading = true
        pointsOrderApi
          .delivery(data)
          .then((res) => {
            hide()
            that.loading = false
            if (res.code === 0) {
              that.$message.success(res.msg)
              this.updateVisible(false)
              that.$emit('done')
            } else {
              that.$message.error(res.msg)
            }
          })
          .catch((e) => {
            that.loading = false
            that.$message.error(e.message)
          })
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
      this.$emit('update:data', this.form)
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('logCompany')
        .then((res) => {
          if (res.code === 0) {
            this.logDict = res.data
            this.logDict.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
