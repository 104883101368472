<template>
  <a-modal
    :width="580"
    :visible="visible"
    :confirm-loading="loading"
    :title="'修改收货信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="收货人:" name="receiptName">
            <a-input
              v-model:value="form.receiptName"
              allow-clear
              placeholder="请输入收货人"
            />
          </a-form-item>
          <a-form-item label="收货人电话:" name="receiptPhone">
            <a-input
              v-model:value="form.receiptPhone"
              allow-clear
              placeholder="请输入收货人电话"
            />
          </a-form-item>
          <a-form-item label="收货人地区:" name="fullCity">
            <!-- <a-input
              v-model:value="form.receiptAreacode"
              allow-clear
              placeholder="请输入收货人地区"
            /> -->
            <a-cascader
              v-model:value="form.fullCity"
              :options="cityData.cityData"
              placeholder="请选择收货人地区"
              popup-class-name="ud-pop-wrap-higher"
            />
          </a-form-item>
          <!-- <a-form-item label="地址:" name="fullCity">
            <a-cascader
              v-model:value="form.fullCity"
              :options="cityData.cityData"
              placeholder="请选择省市区"
              popup-class-name="ud-pop-wrap-higher"
            />
          </a-form-item> -->
          <a-form-item label="收货人详细地址:" name="receiptDetail">
            <a-input
              v-model:value="form.receiptDetail"
              allow-clear
              placeholder="请输入收货人详细地址"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as dictDataApi from '@/api/sys/dictData'
import * as pointsOrderApi from '@/api/jyyw/pointsOrder'
import regions from '@/utils/regions'
export default {
  name: 'receipt-Edit',
  emits: ['done', 'update:visible', 'update:data'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      logDict: [],
      // 省市区数据
      cityData: regions,
      // 表单验证规则
      rules: {
        receiptName: [
          {
            required: true,
            message: '请输入收货人',
            type: 'string',
            trigger: 'blur'
          }
        ],
        receiptPhone: [
          {
            required: true,
            message: '请输入收货人电话',
            type: 'string',
            trigger: 'blur'
          }
        ],
        fullCity: [
          {
            required: true,
            message: '请选择收货人地区',
            type: 'array',
            trigger: 'blur'
          }
        ],
        receiptDetail: [
          {
            required: true,
            message: '请输入收货人详细地址',
            type: 'string',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.queryDataStates()
  },
  mounted() {},
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
      } else {
        this.form = {}
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      if (this.data.receiptAreacode && this.data.receiptAreacode.length === 6) {
        const fullCity = [
          this.data.receiptAreacode.substring(0, 2).padEnd(6, '0'),
          this.data.receiptAreacode.substring(0, 4).padEnd(6, '0'),
          this.data.receiptAreacode.substring(0, 6).padEnd(6, '0')
        ]
        this.form.fullCity = fullCity
      }
    }
  },
  methods: {
    save() {
      const that = this
      const data = {
        orderId: that.form.orderId,
        receiptName: that.form.receiptName,
        receiptPhone: that.form.receiptPhone,
        receiptAreacode: that.form.receiptAreacode,
        receiptDetail: that.form.receiptDetail
      }
      if (that.form.fullCity && that.form.fullCity.length === 3) {
        data.receiptAreacode = that.form.fullCity[2]
      } else {
        data.receiptAreacode = ''
      }
      console.log('fullCity', that.form.fullCity)
      that.$refs.form.validate().then(() => {
        const hide = that.$message.loading('提交中..', 0)
        that.loading = true
        pointsOrderApi
          .receipt(data)
          .then((res) => {
            hide()
            that.loading = false
            if (res.code === 0) {
              that.$message.success(res.msg)
              this.updateVisible(false)
              that.$emit('done')
            } else {
              that.$message.error(res.msg)
            }
          })
          .catch((e) => {
            hide()
            that.loading = false
            that.$message.error(e.message)
          })
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
      this.$emit('update:data', this.form)
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('logCompany')
        .then((res) => {
          if (res.code === 0) {
            this.logDict = res.data
            this.logDict.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
